import SimpleDate from "@/lib/SimpleDate"
import useRpc from '@/src/useRpc'
import { useAppRouter } from "@/src/useAppRouter"
import { pluralCount } from "@/lib/string"
import Link from "next/link"


export default function TrialBanner ({org}) {
  if (org?.showSetupSteps) {
    return <TrialBannerUI />
  } else {
    return null
  }
}


function TrialBannerUI() {
  const router = useAppRouter()

  const [billing] = useRpc('billing.info')

  const today = new SimpleDate()
  const trialEndDate = billing && new SimpleDate(billing.trialEndDate)
  const daysLeftInTrial = Math.max(0, trialEndDate ? today.daysBetween(trialEndDate) : 0)

  if (!billing) return null

  return <>
    <div className="trial-banner">
      <div className="trial-banner-content">
        <div className="bar">

          <Link href={router.path('settings')}>
            <a className="top flex-row spread">
              <div>
                <span>
                  Free trial ends { daysLeftInTrial === 0 ?
                    <>today</>
                  : daysLeftInTrial === 1 ?
                    <>tomorrow</>
                  :
                    <>in {pluralCount(daysLeftInTrial, 'day')}</>
                  }

                </span>
              </div>

              <div>
                <button className="small button is-primary bold nowrap">
                  Finish Setup
                </button>
              </div>
            </a>
          </Link>

        </div>
      </div>
    </div>

    <style jsx>{`
      .trial-banner {
        position: sticky;
        top: 0;
      }
      .bar {
        background-color: #ffa;
        z-index: 20;
        text-align: center;
        transition: all 500ms;
      }
      .top {
        display: flex;
        flex-direction: row;
        padding: 0.2rem 0.5rem;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        color: #333;
        border: 1px solid rgb(255, 232, 189);
      }
      @media only screen and (max-width: 375px) {
        .top {
          font-size: 0.9rem;
        }
      }
    `}</style>
  </>
}
