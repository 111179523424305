/* eslint @next/next/no-img-element: 0 */

import { sortBy } from "@/lib/array"
import SimpleDate from "@/lib/SimpleDate"
import { datesInMonth, datesInWeek, indexInWeek } from "@/src/misc"
import rpc from "@/src/rpc"
import { useAppRouter } from "@/src/useAppRouter"
import useDeviceInfo from "@/src/useDeviceInfo"
import { sendMessage, useMessage } from "@/src/useMessage"
import classNames from "classnames"
import Link from "next/link"
import { useCallback, useEffect, useRef, useState } from "react"
import NoIcon from "./icons/NoIcon"
import PlusIcon from "./icons/PlusIcon"
import LoadingSpinner from "./LoadingSpinner"
import { MiniMonth } from "./MiniMonth"
import useCurrentOrg from "@/src/useCurrentOrg"
import HandRaisedIcon from "./icons/HandRaisedIcon"
import LockClosedIcon from "./icons/LockClosedIcon"
import VideoCameraIcon from "./icons/VideoCameraIcon"
import LoadingOverlay from "./LoadingOverlay"
import StarIcon from "./icons/StarIcon"


export function MonthView ({date}) {

  const router = useAppRouter()

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])

  const [draggingPostGroupId, setDraggingPostGroupId] = useState()
  const [moveInProgress, setMoveInProgress] = useState()
  const [targetDragDate, setTargetDragDate] = useState()

  const {width, height, mobile} = useDeviceInfo()

  const shortDays = (
    // (width > height * 1.5) ||           // Uncomment for wide desktop view
    (width > height * 0.8 && width <= 750) // Small mobile device in landscape
  )

  const popoverOpen = !!(router.query.popover === 'posts' || router.query.popover === 'create')

  date = new SimpleDate(date)
  const today = new SimpleDate()

  const wdays = datesInWeek(date)
  const days = datesInMonth(date)

  const startOfMonth = date.addDays(-1 * (date.dayOfMonth - 1))

  const [sparsePostGroups, setSparsePostGroups] = useState(null)
  const [fullPostGroups, setFullPostGroups] = useState(null)

  const org = useCurrentOrg()
  const prevOrg = useRef(0)
  const prevStartOfMonth = useRef(startOfMonth)


  const [destinations, setDestinations] = useState()
  const hasSocialAccounts = destinations && destinations.filter(d => d.platform !== 'social').length > 0


  useEffect(() => {
    fetchSocialStatus()

    async function fetchSocialStatus () {
      const status = await rpc('social.status')

      // Auto-retry
      if (!status) return setTimeout(fetchSocialStatus, 500)

      // Avoid updating an unmounted component
      if (!mounted.current) return

      const {destinations} = status
      setDestinations(destinations)
    }
  }, [fullPostGroups])


  const loadPostGroupData = useCallback(async ({skipPreload}={}) => {
    if (!skipPreload) {
      // Fetch postGroup ids and dates quickly, so the calendar can show something
      const postGroupSummary = await rpc('postGroups.between', {
        startDate: startOfMonth,
        endDate:   startOfMonth.addMonths(1),
        idsAndDatesOnly: true,
      })

      if (!mounted.current) return // Avoid updating unmounted component

      setSparsePostGroups(postGroupSummary)
    }

    // Then load the rest of the postGroup data (images and captions)
    const postGroupData = await rpc('postGroups.between', {
      startDate: startOfMonth,
      endDate:   startOfMonth.addMonths(1)
    })

    if (!mounted.current) return // Avoid updating unmounted component

    setFullPostGroups(postGroupData)
  }, [startOfMonth])


  useEffect(() => {
    const sameOrg = org?.id === prevOrg.current?.id
    const sameMonth = startOfMonth.equals(prevStartOfMonth.current)

    if (sameOrg && sameMonth) return

    prevOrg.current = org
    prevStartOfMonth.current = startOfMonth

    setSparsePostGroups(null)
    setFullPostGroups(null)

    if (org) loadPostGroupData()

  }, [org, startOfMonth, loadPostGroupData])


  // Used to fade in the loading background on the dates that have postgroups
  const [datesLoaded, setDatesLoaded] = useState(false)
  useEffect(() => {
    let timer = 0
    if (sparsePostGroups) {
      timer = setTimeout(() => {
        if (!mounted.current) return // Avoid updating unmounted component
        setDatesLoaded(!!sparsePostGroups)
      }, 100)
    } else {
      setDatesLoaded(false)
    }
    return () => clearTimeout(timer)
  }, [sparsePostGroups])

  const autoGenDate = startOfMonth.addMonths(-2)
  const showNoPostsYetMessage = autoGenDate > today && !!sparsePostGroups && sparsePostGroups.length === 0

  // While waiting for post groups, fill in the dates with a loading indicator
  const allPostGroups = fullPostGroups || sparsePostGroups
  const loadingPostGroups = !allPostGroups

  const reloadPostGroups = useCallback(() => {
    if (org) loadPostGroupData({skipPreload: true})
  }, [org, loadPostGroupData])

  useMessage('refreshCalendar', () => reloadPostGroups())
  useMessage('brandingStarted', () => reloadPostGroups())
  useMessage('generatingImage', () => reloadPostGroups())

  const updatesInProgress = !!(fullPostGroups && fullPostGroups.find(pg => pg.updating))

  useEffect(() => {
    let timer = 0
    if (updatesInProgress) {
      timer = setTimeout(() => sendMessage('refreshCalendar'), 10000)
    }
    return () => clearTimeout(timer)
  }, [updatesInProgress, fullPostGroups])


  const prevMonth = date.addMonths(-1)
  const nextMonth = date.addMonths(1)
  const prevMonthName = [prevMonth.monthName, prevMonth.year].join('-')
  const nextMonthName = [nextMonth.monthName, nextMonth.year].join('-')

  // Used to avoid jumping more than one month at a time
  const monthJumpCooldown = useRef()
  const miniMonthHoverTimerRef = useRef()

  // Need to prevent default on dragEnter and dragOver so the browser knows these are drop targets
  function dragEnterMiniMonth (e) {
    e.preventDefault()

    if (!draggingPostGroupId) return

    const targetName = e.currentTarget.dataset.dragTarget

    // Avoid jumping to the next/previous month repeatedly unless they drag
    // off the mini-month box and then back into it again.
    if (monthJumpCooldown.current) {
      return
    }

    setTargetDragDate(targetName)

    let targetMonth = null
    if (targetName === prevMonthName) {
      targetMonth = prevMonth
    } else if (targetName === nextMonthName) {
      targetMonth = nextMonth
    }

    if (targetMonth) {
      miniMonthHoverTimerRef.current = setTimeout(() => {
        router.goto(targetMonth.toString())
        monthJumpCooldown.current = setTimeout(() => monthJumpCooldown.current = null, 500)
      }, 500)
    }
  }

  // Need to prevent default on dragEnter and dragOver so the browser knows these are drop targets
  function dragLeaveMiniMonth (e) {
    e.preventDefault()

    if (!draggingPostGroupId) return

    clearTimeout(miniMonthHoverTimerRef.current)

    setTargetDragDate(v => {
      if (v && v.endsWith('-month')) {
        return null
      } else {
        return v
      }
    })
  }


  function preventContextMenu (e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }


  return <>
    <div className={classNames("full-height relative flex-col", {'dates-loaded': datesLoaded, 'short-days': shortDays})}>

      <div className="month-view flex-col absolute-fill">
        <LoadingOverlay visible={loadingPostGroups} delay={500}/>

        <div className={classNames("expandable", {collapsed: !showNoPostsYetMessage})}>
          <div className="flex-center bg-yellow border-yellow no-border-bottom no-border-left no-border-right  p-2">
            <div className="text-center flex-row gap-3">
              <div className="text-sm">
                { showNoPostsYetMessage ?
                  <>
                    Posts for this month will be <span className="nowrap">created on {autoGenDate.monthName} 1st</span>.
                  </>
                :
                  <>&nbsp;</>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="day-names">
          { wdays.map(d => {

            return (
              <div className="day-name" key={d.dayName}>
                { d.dayName.substring(0, 3) }
              </div>
            )

          }) }
        </div>

        <div className="days-of-month flex-grow">
          <div data-drag-target={prevMonthName} className={classNames("mini-month prev-month day full relative", {selected: targetDragDate === prevMonthName})} onDragEnter={dragEnterMiniMonth} onDragLeave={dragLeaveMiniMonth}>
            <Link href={router.path(prevMonth.toString())}>
              <a className="absolute-fill">
                { !!org &&
                  <MiniMonth date={date.addMonths(-1)}/>
                }
                <div className="highlight transition absolute-fill"/>
              </a>
            </Link>
          </div>

          { days.map((d, i) => {
            const past = d < today
            const isToday = d.equals(today)
            const selected = !!((popoverOpen && d.equals(date)) || targetDragDate === d.toString())
            const postGroups = allPostGroups ? allPostGroups.filter(pg => pg.date === d.toString()) : []

            let href = router.path('create', {date: d.toString()})

            function dragStart (e) {
              e.dataTransfer.effectAllowed = 'move'

              const postGroupId = parseInt(e.currentTarget.id.split('-').pop(), 10)

              e.dataTransfer.setData('text/plain', `post-group-${postGroupId}`)

              setDraggingPostGroupId(postGroupId)
            }

            // Need to prevent default on dragEnter and dragOver so the browser knows these are drop targets
            function dragEnter (e) {
              e.preventDefault()

              // If this is a not a post group being dragged, ignore it
              if (!draggingPostGroupId) return

              if (d >= today) {
                setTargetDragDate(d.toString())
              } else {
                setTargetDragDate(null)
              }
            }

            function dragLeave (e) {
            }

            function dragOver  (e) {
              e.preventDefault()
            }

            function dragEnd (e) {
                setDraggingPostGroupId(null)
                setTargetDragDate(null)
            }

            function drop (e) {
              e.preventDefault()

              if (!targetDragDate) return

              const data = e.dataTransfer.getData('text/plain')

              if (data.startsWith('post-group-')) {
                const id = data.split('-').pop()

                // Make the change locally first, then send the request to the server
                setFullPostGroups(records => {
                  if (!records || typeof records.map !== 'function') {
                    // TODO: Find out when this happens and handle it better
                    return records
                  }

                  return records.map(pg => {
                    if (pg.id.toString() === id) {
                      pg.date = targetDragDate
                    }
                    return pg
                  })
                })

                move()

                async function move () {
                  setMoveInProgress(true)
                  await rpc('postGroups.setDate', {id, date: targetDragDate})
                  setMoveInProgress(false)
                  setDraggingPostGroupId(null)
                  setTargetDragDate(null)
                  sendMessage('refreshCalendar')
                }

                return
              }


              var files = e.dataTransfer.files
              var count = files.length

              if (count) {
                console.log("--> TODO: Allow drag and drop onto the calendar")
              }

              for (var i = 0; i < files.length; i++) {
                console.log(" File " + i + ":\n(" + (typeof files[i]) + ") : <" + files[i] + " > " +
                        files[i].name + " " + files[i].size + "\n");
              }
            }

            const style = {}
            const dayLoading = moveInProgress && targetDragDate === d.toString()
            if (i === 0) style.gridColumnStart = indexInWeek( d ) + 1
            const classes = classNames("day absolute-fill", {selected, past, today: isToday})

            const dayOccupied = postGroups.length > 0

            // On mobile devices the finger is too fat to tap on the edge of a
            // day that already has posts, to add a another post, so we only
            // allow tapping on days that have no posts. Otherwise, when the
            // tries to tap on a post (to open it), it could trigger the add
            // post action instead -- which is very annoying and confusing.
            const canTapDayToAdd = !mobile || !dayOccupied

            return (
              <div key={d.toString()} className={classes} style={style} draggable="false" onDragEnter={dragEnter} onDragLeave={dragLeave} onDragOver={dragOver} onDrop={drop}>
                <div className={classNames("day-number")}>
                  { isToday ? 'Today' : d.dayOfMonth }
                </div>

                <div className="relative flex-grow">

                  { postGroups.length > 0 ?
                    <div className="flex-col gap-1 absolute-fill post-group-wrapper">
                      {sortBy(postGroups, 'id').map(pg => {

                        const loading = !(pg.imageUrl || pg.caption)
                        const editable = pg.date >= today && !pg.readonly
                        const dragging = draggingPostGroupId === pg.id
                        const updating = !!pg.updating

                        // NOTE: Not showing approval warning until AFTER they connect social accounts
                        const approvalNeeded = !!pg.needsApproval && !pg.approvedAt && hasSocialAccounts && pg.date >= today && !pg.locked

                        const href = ( pg.locked ?
                          router.path(pg.date, {popover: 'locked', id: ''})
                        :
                          router.path(pg.date, {popover: 'edit', id: pg.id})
                        )


                        return (
                          <Link href={href} key={pg.id} >
                            <a id={`post-group-${pg.id}`} className={classNames("post-group relative z-2", {dragging, 'with-image': !!pg.imageUrl, 'no-drag': !editable})} draggable={editable} onDragStart={dragStart} onDragEnd={dragEnd} onContextMenu={preventContextMenu}>
                              <div className={classNames("post-wrapper transition-slow relative flex-grow", {loading, updating})}>
                                { pg.isVideo &&
                                  <div className="absolute-fill flex-center video-icon">
                                    <div className="absolute left bottom center">
                                      <div className="bg-black opacity-70 absolute-full rounded sm_rounded-lg"></div>
                                      <div className="relative opacity-80 px-1 sm_px-2 sm_py-1 white flex-row gap-1 flex-align-center sm_flex-centered semi-bold text-xs shadow">
                                        <VideoCameraIcon/>
                                        <div className="hidden sm_flex">Video</div>
                                      </div>
                                    </div>
                                  </div>}
                                { pg.imageUrl ?
                                  <img draggable="false" src={pg.imageUrl} className="post-image" alt={pg.name}/>
                                : pg.caption ?
                                  <>
                                    <div className={classNames("text-only-post", {locked: pg.locked})}>
                                      {pg.caption}
                                    </div>
                                    <div className="text-only-post-overflow-fade"/>
                                  </>
                                :
                                  <div style={{height: 999}}>
                                    {/* Keep the post-wrapper from collapsing while posts are loading */}
                                  </div>
                                }
                                <div className="loading-background absolute-fill flex-center white" style={{transitionDelay: (i * 20) + 'ms'}}>
                                  <button className="button is-loading full-width full-height border:0 rounded-none">
                                  </button>
                                </div>

                                { pg.locked &&
                                  <div className="z-1 absolute-fill flex flex-align-end">
                                    <div className="absolute-fill p-1 flex-row flex-centered bg-white opacity-30">
                                    </div>
                                    <div className="z-2 absolute-fill p-1 flex-row flex-centered">
                                      <div className="flex-row flex-center black">
                                        <LockClosedIcon style={{width: "3rem", height: "3rem", maxWidth: "60%", maxHeight: "60%"}}/>
                                      </div>
                                    </div>
                                  </div>
                                }

                                { approvalNeeded &&
                                  <>
                                    <div className="approval-needed-warning z-1 absolute-fill flex-align-end">
                                      <div className="absolute bottom full-width flex-row flex-center my-2" style={{height: '2.5rem'}}>
                                        <div className="fit-content p-1 flex-row flex-centered bg-yellow orange border-yellow bold shadow-xl rounded-lg small" style={{height: '2.5rem'}}>
                                          <div className="flex-row flex-center">
                                            <HandRaisedIcon />
                                          </div>
                                          <span className="needs-approval-text text-left text-xs px-1">
                                            Needs<br/>Approval
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }

                                { updating &&
                                  <div className={classNames("z-2 absolute-fill flex-col flex-centered", {"m-2": !mobile})}>
                                    <div className="absolute-fill bg-white opacity-95 rounded-lg shadow-lg"></div>
                                    <div className="z-1 absolute-fill flex-col flex-centered">
                                      <div>
                                        <LoadingSpinner size="tiny"/>
                                      </div>
                                      <div className="text-sm semi-bold updating-post-text opacity-90">
                                        Updating
                                      </div>
                                    </div>
                                  </div>
                                }
                                <div className="sneeze-guard" />
                              </div>

                              { pg.greatMetrics &&
                                <div className="z-1 absolute-fill flex-align-end">
                                  <div className="full-width full-height flex-row flex-center">
                                    <div className="absolute-fill bg-white opacity-50"></div>
                                    <div className="flex-col flex-centered z-up">
                                      <div className="flex-row flex-center text-2xl big-icon orange opacity-70">
                                        <StarIcon />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }

                            </a>
                          </Link>
                        )
                      })}
                    </div>
                  :
                    null
                  }
                </div>

                { canTapDayToAdd &&
                  ( d >= today ?
                    <Link href={href} key={d.toString()}>
                      <a className={classNames("add-button-container absolute-fill flex-center", {occupied: dayOccupied})}>
                        <div className="add-button blue">
                          <PlusIcon/>
                        </div>
                      </a>
                    </Link>
                  :
                    <div className="no-button-container absolute-fill flex-center"
                      title="Sorry. Can't add a post here. The time machine is broken 😜">
                      <div className="add-button light-gray">
                        <NoIcon/>
                      </div>
                    </div>
                  )
                }

                { !!dayLoading &&
                  <div className="absolute-fill bg-white-transparent flex flex-center z-5">
                    <LoadingSpinner/>
                  </div>
                }

                <div className="highlight transition absolute-fill"/>
              </div>
            )

          }) }

          <div data-drag-target={nextMonthName} style={{gridColumnStart: 7}} className={classNames("mini-month next-month day full relative", {selected: targetDragDate === nextMonthName})} onDragEnter={dragEnterMiniMonth} onDragLeave={dragLeaveMiniMonth}>
            <Link href={router.path(nextMonth.toString())}>
              <a className="absolute-fill">
                { !!org &&
                  <MiniMonth date={date.addMonths(1)}/>
                }
                <div className="highlight transition absolute-fill"/>
              </a>
            </Link>
          </div>

        </div>
      </div>
    </div>



    <style jsx>{`
      .expandable {
        transition: max-height 800ms;
        overflow: hidden;
        max-height: 4rem;
      }
      .collapsed {
        max-height: 0;
        transition: max-height 200ms;
      }
      .big-icon :global(svg) {
        width: 60%;
        height: 60%;
        max-width: 150px;
      }
      .updating-post-text,
      .needs-approval-text {
        line-height: 0.8rem;
      }
      @media screen and (max-width: 1079px) {
        .updating-post-text,
        .needs-approval-text {
          display: none;
        }
      }
      .next-month {
        border-left: 1px solid white;
      }
      .prev-month {
        border-right: 1px solid white;
      }
      .post-group {
        transition: opacity 100ms 500ms;
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
        height: 100%;
      }
      .post-group.with-image {
        /*
          To keep image from getting cut off on the sides...
          height: unset;
        */
      }
      .dragging {
        opacity: 0.2;
        transition: opacity 100ms 10ms;
      }
      .sneeze-guard {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
      }
      .month-view {
        background-color: #e6e6e6;
        width: 100%;
      }
      .day-names {
        margin-top: 1px;
        margin-bottom: 1px;
      }
      .day-names,
      .days-of-month {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        grid-auto-rows: minmax(0, 1fr);
        gap: 1px;
        min-height: 0;
        min-width: 0;
      }
      .days-of-month {
        height: 100%;
        align-content: stretch;
      }
      .day-name {
        padding: 0.25rem 0.5rem;
        text-align: center;
        color: #666699;
      }
      .day, .day-name {
        background-color: white;
      }
      .day {
        display: flex;
        flex-direction: column;
        text-align: right;
        position: relative;
        min-width: 0;
        min-height: 0;
        /* Prevent long-press context menu, so we can drag and drop */
        -webkit-touch-callout: none;
      }
      .day.selected .highlight {
        box-shadow: inset 0 0 4px 6px #3499cc;
        background-color: #3499cc33;
        z-index: 11;
      }
      .day-number {
        color: #666699;
        background-color: #ffffffe3;
        padding: 0.05em 0.5em;
        text-align: right;
        min-width: 1.5em;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        pointer-events: none;
      }
      .today .day-number {
        font-weight: bold;
        color: #4e9428;
      }
      .past {
        background-color: #f6f6f6;
      }
      .past .day-number {
        color: #CCC;
        background-color: #f6f6f6;
      }
      .no-drag .post-wrapper,
      .past .post-wrapper {
        opacity: 0.4;
      }
      .no-drag .post-wrapper:hover,
      .past .post-wrapper:hover {
        opacity: 1;
      }
      @media screen and (max-width: 765px) {
        .day-number {
          font-size: 0.9rem;
          border-bottom-left-radius: 0.3rem;
        }
        .day-name {
          font-size: 0.9rem;
        }
      }
      @media screen and (max-width: 400px) {
        .day-number {
          font-size: 0.8rem;
        }
        .day-name {
          font-size: 0.8rem;
        }
      }
      .short-days .day-number {
        font-size: 0.9rem;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        border-bottom-left-radius: 0.3rem;
      }
      .post-group-wrapper {
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 6px;
      }
      @media screen and (max-width: 765px) {
        .post-group-wrapper {
          padding: 0
        }
      }
      .short-days .post-group-wrapper {
        padding: 0;
      }
      .short-days .post-group {
        border-radius: 0;
      }
      .post-wrapper {
        overflow: hidden;
        opacity: 1;
        display: flex;
        height: 100%;
      }
      .text-only-post,
      .post-image {
        object-fit: cover;
        object-position: center;
        width: 100%;
        cursor: pointer;
        transition: all 150ms;
        background-color: #c5e5b4;
      }
      .text-only-post {
        min-height: 4rem;
        white-space: pre-line;
      }
      .text-only-post.locked {
        filter: blur(3px);
      }
      .updating .post-image {
        filter: blur(4px);
      }
      .post-group {
        transition: all 150ms;
      }
      .post-group:hover {
        filter: brightness(0.8);
      }
      .post-wrapper.loading .post-image {
        opacity: 0.2;
      }
      .updating-spinner {

      }
      .loading-background .button {
        background-color: #c5e5b4;
        border-radius: 0;
        border: none;
      }
      .loading-background .button.is-loading::after {
        border-left-color: #2f8ab8;
        border-bottom-color: #2f8ab8;
      }

      .loading-background {
        color: #666699;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 200ms;
        z-index: 1;
        pointer-events: none;
      }
      .dates-loaded .loading .loading-background {
        opacity: 1;
      }
      .text-only-post {
        overflow: hidden;
        text-align: left;
        padding: 0.4rem 0.5rem 0;
        font-weight: 700;
        color: white;
        background-color: #666699;
        font-size: 0.8rem;
        line-height: 1rem;
        height: 100%;
      }
      .text-only-post-overflow-fade {
        background: linear-gradient(to top, #666699, #666699 30%, #66669933);
        height: 1.2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
      @media screen and (max-width: 750px) {
        .text-only-post {
          padding: 0.3rem 0.4rem 0;
          font-weight: 700;
        }
        .text-only-post-overflow-fade {
          height: 1rem;
        }
      }
      @media screen and (max-width: 500px) {
        .text-only-post {
          padding: 0.15rem 0.2rem 0;
          font-weight: normal;
          font-size: 0.6rem;
          line-height: 0.7rem;
        }
        .text-only-post-overflow-fade {
          height: 0.5rem;
        }
      }
      .no-button-container,
      .add-button-container {
        transition: all 150ms;
        cursor: pointer;
        z-index: 1;
      }
      .no-button-container {
        cursor: not-allowed;
      }
      .add-button {
        display: inline-block;
        position: relative;
        transition-delay: 75ms;
        transition-property: all;
        transform: scale(0.75);
        text-align: center;
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0.5rem;
        transition: transform 150ms 75ms, opacity 150ms;
        text-align: center;
        opacity: 0;
      }
      .mini-month:hover .highlight,
      .add-button-container:hover {
        background-color: #3499cc11;
        box-shadow: inset 0 0 2px 4px #3499cc33;
      }
      .no-button-container:hover {
        background-color: #cccccc22;
      }
      .no-button-container:hover .add-button,
      .add-button-container:hover .add-button {
        transform: scale(1.5);
        opacity: 0.5;
      }
      .day.selected .add-button {
        transform: scale(1.5);
        opacity: 1;
      }
      .add-button-container.occupied {
        height: 25px;
        justify-content: flex-start;
      }
      .add-button-container.occupied:hover .add-button {
        transform: scale(0.8);
      }

    `}</style>
  </>
}
