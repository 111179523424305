import Link from 'next/link'
import MusicalNoteIcon from "./icons/MusicalNoteIcon";
import useRpc from "@/src/useRpc";
import { useRef, useState } from "react";
import { sendMessage, useMessage } from "@/src/useMessage";
import { switchToOrg, logout } from "@/src/session";
import { useAppRouter } from "@/src/useAppRouter";
import classNames from "classnames";
import UserCircleIcon from "./icons/UserCircleIcon";
import LoadingSpinner from "./LoadingSpinner";
import PlusCircleIcon from "./icons/PlusCircleIcon";
import { sortBy } from "@/lib/array";
import ArrowRightOnRectangleIcon from "./icons/ArrowRightOnRectangleIcon";
import rpc from '@/src/rpc';
import Modal from './Modal';
import UserForm from './UserForm';
import useOnClickOutside from '../src/useOnClickOutside'
import TrashIcon from './icons/TrashIcon';
import WarningIcon from './icons/WarningIcon';
import useCurrentOrg from '@/src/useCurrentOrg';
import NewCustomerForm from './NewCustomerForm';


export default function AccountMenu ({}) {
  const router = useAppRouter()

  const currentOrg = useCurrentOrg()
  const [orgs, _, reloadOrgs] = useRpc('orgs.mine')
  const [user, __, reloadUser] = useRpc('users.me')

  useMessage('orgUpdated', () => reloadOrgs())

  const [editingUser, setEditingUser] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const [showCreateDemoUI, setShowCreateDemoUI] = useState(false)

  const buttonRef = useRef(null)
  const menuRef = useRef(null)
  useOnClickOutside(menuRef, e => {
    if (buttonRef.current.contains(e.target)) return
    if (editingUser) return

    setMenuVisible(false)
  })

  const loaded = (orgs && (currentOrg || orgs.length === 0))
  if (!loaded) return <LoadingSpinner padded/>

  const orgList = sortBy(orgs.slice(0), o => o.id)

  // When in "support" mode we have to add the current org into the list
  if (currentOrg && !orgList.find(org => org.id === currentOrg.id)) {
    orgList.push(currentOrg)
  }

  const yourOrgs    = orgList.filter(o => !o.demo && !o.supportMode)
  const demoOrgs    = orgList.filter(o => o.demo)
  const supportOrgs = orgList.filter(o => o.supportMode)

  const yourAccountLabel = 'Your Compan' + (yourOrgs.length === 1 ? 'y' : 'ies')

  const canSeeOrgId    = user?.canGoBackstage
  const canGoBackstage = user?.canGoBackstage
  const canCreateDemos = user?.canCreateDemos

  const orgName = currentOrg?.name || `Org #${currentOrg?.id}`

  if (!user) return null

  async function editCurrentUser() {
    setEditingUser(true)
  }

  async function saveUser(user) {
    const {id, firstName, lastName} = user
    user = await rpc('users.updateUser', {id, firstName, lastName})
    await reloadUser()
    setEditingUser(false)
  }

  function handleDemoOrgClick (e) {
    e.preventDefault()
    setShowCreateDemoUI(true)
  }

  async function deleteOrg(org) {
    sendMessage('loading', true)

    // When deleting the current org, switch to another org first
    let reloadNeeded = false
    if (org.id === currentOrg.id) {
      const otherOrgs = orgs.filter(o => o.id !== org.id)
      const differentOrg = otherOrgs[0]
      if (differentOrg) {
        await pickOrg(differentOrg)
      } else {
        // If there are no other orgs, we need to reload the page after deleting
        reloadNeeded = true
      }
    }

    await rpc('orgs.delete', {orgId: org.id})
    await reloadOrgs()

    if (reloadNeeded) window.location.reload()
    sendMessage('loading', false)
  }

  async function goBackstage (e) {
    e.preventDefault()
    sendMessage('loading', true)

    if (currentOrg?.demo || (currentOrg?.supportMode && user.canViewSignups)) {
      router.push(`/backstage/orgs/${currentOrg.id}/show`)
    } else {
      router.push('/backstage')
    }
    sendMessage('loading', false)
  }

  async function signout() {
    sendMessage('loading', true)
    await logout()
    router.push('/login')
  }

  async function clickOrg (org) {
    if (org && org?.id === currentOrg.id) {
      sendMessage('editOrg', org)
    } else {
      await pickOrg(org)
    }
  }

  async function pickOrg (org, url) {
    if (org && org.id === currentOrg.id) return

    sendMessage('loading', true)
    await switchToOrg(org)
    if (url) {
      window.location.href = url
    } else {
      window.location.reload()
    }
  }

  return <>
    <button className={classNames("mx-1 overflow-hidden text-left clickable transition border border-white bg-white pr-3 py-1 dark-gray rounded", {
        orange:                   !!currentOrg?.supportMode,
        'bg-orange-transparent':  !!currentOrg?.supportMode,
        'border-orange':          !!currentOrg?.supportMode,
      })}
      onClick={() => setMenuVisible(v => !v)}
      ref={buttonRef}
    >
      <div className='flex-row gap-2'>
        <div className='flex-no-shrink'>
          <UserCircleIcon/>
        </div>
        <div className="flex-col flex-align-start" style={{maxWidth: 200}}>
          <span className="nowrap text-base ellipsis overflow-hidden full-width">
            {orgName}
          </span>
          { currentOrg?.supportMode ?
            <div className='text-2xs pulse'>
              Support Mode
            </div>
          :
            <div className="text-2xs gray nowrap ellipsis overflow-hidden full-width mt--125">
              {user.email}
            </div>
          }
        </div>
      </div>
    </button>

    { menuVisible &&
      <div className="p-2 fixed top position-right max-width-400" style={{top: 56}} ref={menuRef}>
        <div className="shadow-2xl pb-2 bg-white rounded-lg flex-col" style={{maxHeight: '80vh', maxWidth: '95vw', minWidth: 300}}>
          <div className="overflow-auto-y overflow-hidden-x flex-shrink">

            <div className=''>

              {
                ([
                  [yourAccountLabel,   yourOrgs],
                  ['Demo Accounts',    demoOrgs],
                  ['Other Companies',   supportOrgs],
                ]).map(([title, orgs]) => {
                  const demoSection = title === 'Demo Accounts'
                  const showOrgs = orgs.length > 0 || (demoSection && canCreateDemos)

                  if (!showOrgs) return null

                  return (
                    <div className="flex-grow" key={title}>
                      <div className="py-2 pl-3 pr-2 bg-f3f3f3 flex-row spread">
                        <div className="gray semi-bold text-sm">
                          {title}
                        </div>
                      </div>

                      <div className='text-left px-2 my-2'>
                        {orgs.map(org => {
                          const isCurrent = org && org.id === currentOrg.id
                          const highlight = isCurrent && orgList.length > 1
                          const greenHighlight  = highlight && !org.supportMode
                          const orangeHighlight = highlight && org.supportMode
                          return (
                            <Link href={`/orgs/${org.id}`} key={org.id}>
                              <a
                                className={classNames('py-2 px-3 my-1 rounded clickable default-text-color  block', {
                                  "bg-light-green":        greenHighlight,
                                  "border":                greenHighlight,
                                  "border-green":          greenHighlight,
                                  'orange':                orangeHighlight,
                                  'bg-orange-transparent': orangeHighlight,
                                  'border-orange':         orangeHighlight,
                                  "nameless":              !org.name,
                                })}
                                onClick={e => {
                                  // Don't prevent default if the user is trying to open in a new tab
                                  if (e.metaKey || e.ctrlKey) return

                                  e.preventDefault()
                                  clickOrg(org)
                                }}
                              >
                                <div className='flex-row spread gap-2'>

                                  <div className='flex-col flex-grow flex-justify-center'>
                                    <div className={classNames('forcewrap', {italics: !org.name, gray: !org.name})}>
                                      {org.name || `Org #${org.id}`}
                                    </div>

                                    { canGoBackstage &&
                                      <div className='flex-row gap-1 text-2xs gray italic'>
                                        <span>
                                          {[canSeeOrgId ? `Org #${org.id}` : '', org.type].filter(v => v).join(' - ')}
                                        </span>
                                        { org.supportMode &&
                                          <span className="orange bold">
                                            (Support Mode)
                                          </span>
                                        }
                                      </div>
                                    }
                                  </div>
                                  { org.demo ?
                                    <div className={classNames('red pointer p-1 mr--2 flex opacity-50 transition hover_opacity-100 hover_bg-light-red-transparent rounded')} onClick={e => {e.stopPropagation(); e.preventDefault(); deleteOrg(org)}}>
                                      <div className='tooltip-container'>
                                        <div className='tooltip' style={{left: 'unset', right: '0.5rem', top: '1rem'}}>
                                          Delete Account
                                        </div>
                                      </div>
                                      <TrashIcon/>
                                    </div>
                                  : org.supportMode ?
                                    <span className='orange mr--2 p-1 flex'>
                                      <div className='tooltip-container'>
                                        <div className='tooltip' style={{left: 'unset', right: '0.5rem', top: '1rem'}}>
                                          CAUTION: Support Mode
                                        </div>
                                      </div>
                                      <WarningIcon/>
                                    </span>
                                  :
                                    null
                                  }

                                </div>
                              </a>
                            </Link>
                          )
                        })}

                        { demoSection &&
                          <div className='p-2 flex-row gap-2 pointer clickable rounded dark-gray' onClick={handleDemoOrgClick}>
                            <div className='flex gray'>
                              <PlusCircleIcon/>
                            </div>
                            <div>
                              Create a Demo Account
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  )
                })
              }

              <div className="py-2 px-3 gray text-sm semi-bold bg-f3f3f3">
                Your Profile
              </div>

              <div className='flex-row gap-2 p-2'>
                <div className="py-2 px-3 flex-grow overflow-hidden clickable rounded dark-gray" onClick={editCurrentUser}>
                  <div className="flex-col full-width relative overflow-hidden">
                    <span>
                      { (user.firstName || user.lastName) ?
                        <>{user.firstName} {user.lastName}</>
                      :
                        <span className="gray">
                          You <span className='text-sm italics light-gray'>(no name)</span>
                        </span>
                      }
                    </span>
                    <span className="overflow-hidden tiny gray mt--125 ellipsis overflow-hidden full-width">
                      {user.email}
                    </span>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className='flex-row gap-2 px-2 pt-2 border-top'>
              <div className="flex-grow flex-row gap-2 p-2 relative clickable rounded dark-gray" onClick={signout}>
                <span className="gray flex">
                  <ArrowRightOnRectangleIcon/>
                </span>
                Sign Out<br/>
              </div>

              { canGoBackstage &&
                <div className="flex-grow">
                  <Link href="/backstage">
                    <a className="flex-row no-wrap gap-2 p-2 relative clickable rounded dark-gray" onClick={goBackstage}>
                      <span className="gray flex"><MusicalNoteIcon/></span>
                      Go Backstage
                    </a>
                  </Link>
                </div>
              }
            </div>
        </div>
      </div>
    }

    { showCreateDemoUI &&
      <Modal visible onClickOutside={() => setShowCreateDemoUI(false)}>
        <NewCustomerForm
          user={user}
          demo={true}
          onClose={() => setShowCreateDemoUI(false)}
        />
      </Modal>
    }

    { (editingUser && user) &&
      <Modal visible>
        <div className='double-padded content'>
          <UserForm
            user={user}
            onCancel={() => setEditingUser(false)}
            onSubmit={saveUser}
            allowEdits
          />
        </div>
      </Modal>
    }

    <style jsx>{`
      hr {
        margin: 0.5rem;
      }
    `}</style>

  </>

}
